import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app

const ImageLightbox = ({ images, isOpen, photoIndex, onUpdateIndex, onClose }) => {

  return (
    <div>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => onClose()}
          onMovePrevRequest={() =>
            onUpdateIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            onUpdateIndex((photoIndex + 1) % images.length)
          }
        />
      )}
    </div>
  );
}

export default ImageLightbox